import {createReducer} from '@reduxjs/toolkit'
import {CHANGE_EVENT} from '../actions/event'
import {selectTicket, unselectTicket, selectTickets} from '../actions/selected-tickets'
import {SelectedTickets} from '../types'
import {handlePlaceRemove, handlePlaceSelect} from '../actions/seating'

const defaultState: SelectedTickets = {}

export const selectedTickets = createReducer(defaultState, builder => {
  builder
    .addCase(selectTicket, (state, {payload: {ticketId, count}}) => {
      return {
        ...state,
        [ticketId]: {
          quantity: count,
        },
      }
    })
    .addCase(selectTickets, (state, {payload}) => ({...state, ...payload}))
    .addCase(unselectTicket, (state, {payload: {ticketId, count}}) => ({
      ...state,
      [ticketId]: {
        quantity: count,
      },
    }))
    .addCase(CHANGE_EVENT, () => defaultState)
    .addCase(handlePlaceSelect.fulfilled, (state, {payload}) => {
      const {
        ticket: {ticketId, count, placeIds},
      } = payload

      return {
        ...state,
        [ticketId]: {
          quantity: count,
          placeIds,
        },
      }
    })
    .addCase(handlePlaceRemove.fulfilled, (state, {payload}) => {
      const {
        ticket: {ticketId, count, placeIds},
      } = payload

      return {
        ...state,
        [ticketId]: {
          quantity: count,
          placeIds,
        },
      }
    })
})
