import {Category, DescribeSeatingPlanResponse, Type} from '@wix/ambassador-seating-v1-seating-plan/types'
import {createAction, createAsyncThunk} from '@reduxjs/toolkit'
import {getEventId} from '@wix/wix-events-commons-statics'
import {SelectedPlace} from '../types/seating'
import {getTicketDefinitionByExternalId} from '../selectors/seating'
import {getSelectedTicketQuantity} from '../selectors/selected-tickets'
import {isChooseSeatMode} from '../selectors/navigation'
import {ThunkConfig} from './interfaces'
import {SelectUnselectTicket} from './selected-tickets'

export const describeSeatingPlan = createAsyncThunk<DescribeSeatingPlanResponse, void, ThunkConfig>(
  'DESCRIBE_SEATING_PLAN',
  async (_, {getState, extra: {serverApi}}) => {
    const plan = await serverApi.describeSeatingPlan(getEventId(getState().event))
    const {tickets} = getState()
    plan.plan.categories = plan.plan.categories.filter(({externalId}) =>
      getTicketDefinitionByExternalId(tickets, externalId),
    )

    return plan
  },
)

export const selectPrice = createAsyncThunk<Category[], string, ThunkConfig>(
  'SELECT_PRICE',
  (selectedPrice, {getState}) => {
    const state = getState()
    const categories = state.seating.plan.categories
    return selectedPrice
      ? categories.filter(category => {
          const ticketDefinition = getTicketDefinitionByExternalId(state.tickets, category.externalId)
          return selectedPrice === ticketDefinition.price.value
        })
      : categories
  },
)

export const handlePlaceSelect = createAsyncThunk<
  {ticket: SelectUnselectTicket; places: SelectedPlace[]},
  {place: SelectedPlace; count?: number},
  ThunkConfig
>('HANDLE_PLACE_SELECT', ({place, count}, {getState}) => {
  const state = getState()
  const ticketId = place.ticketId
  const placeId = place.id
  const {selectedPlaces} = state.seating
  const chooseSeatMode = isChooseSeatMode(state)

  if (place.elementType === Type.AREA && !chooseSeatMode) {
    count = count === undefined ? selectedPlaces.filter(({id}) => id === placeId).length + 1 : count
  } else {
    const selected = Boolean(selectedPlaces.find(({id}) => id === placeId))
    count = selected ? 0 : 1
  }

  const places = [
    ...(chooseSeatMode ? [] : selectedPlaces.filter(({id}) => id !== placeId)),
    ...new Array(count).fill(place),
  ]

  const ticket: SelectUnselectTicket = {
    ticketId,
    count: chooseSeatMode
      ? 1
      : getSelectedTicketQuantity(state.selectedTickets, ticketId) -
        getSelectedTicketQuantity(state.selectedTickets, ticketId, placeId) +
        count,
    placeIds: places.map(({id}) => id),
  }

  return {
    ticket,
    places,
  }
})

export const handlePlaceRemove = createAsyncThunk<
  {ticket: SelectUnselectTicket; places: SelectedPlace[]},
  {placeId: string; ticketId: string},
  ThunkConfig
>('HANDLE_PLACE_REMOVE', ({placeId, ticketId}, {getState}) => {
  const state = getState()
  const selectedPlaces = [...state.seating.selectedPlaces]
  selectedPlaces.splice(
    selectedPlaces.findIndex(({id}) => id === placeId),
    1,
  )

  const currentPlaceIds = selectedPlaces.map(({id}) => id)

  const ticket: SelectUnselectTicket = {
    ticketId,
    count: getSelectedTicketQuantity(state.selectedTickets, ticketId) - 1,
    placeIds: currentPlaceIds,
  }

  return {ticket, places: selectedPlaces}
})

export const selectLocation = createAction<string>('SELECT_LOCATION')

export const setShowAccessibilityMode = createAction<boolean>('SET_SHOW_ACCESSIBILITY_MODE')
