import {Place, SeatingPlan} from '@wix/ambassador-seating-v1-seating-plan/types'
import {getFormattedMoney, WIX_EVENTS_TICKET_DEFINITION_FQDN} from '@wix/wix-events-commons-statics'
import type {TFunction} from '@wix/wix-i18n-config'
import {SelectedPlace} from '../types/seating'
import {getFee, getTax} from './tax-and-fee'

interface GetPlaceWithTicketInfoArgs {
  plan: SeatingPlan
  tickets: wix.events.ticketing.TicketDefinition[]
  event: wix.events.Event
  place: Place
  categoryId: number
  t: TFunction
}

export const getPlaceWithTicketInfo = ({
  plan,
  tickets,
  event,
  place,
  categoryId,
  t,
}: GetPlaceWithTicketInfoArgs): SelectedPlace => {
  const {id, elementType, label, capacity} = place
  const category = plan.categories.find(item => item.id === categoryId)

  if (!category) {
    return null
  }

  const {
    externalId,
    config: {color},
  } = category
  const ticketDefinition = getTicketDefinitionByExternalId(tickets, externalId)
  const [sectorId, rowId] = id.split('-')
  const sector = plan.sectors.find(item => item.id === Number(sectorId))
  const row = sector.elements.find(item => item.id === Number(rowId))

  return {
    id,
    elementType,
    sector: sectorId === '0' ? undefined : sector.title,
    seat: label,
    row: row.title,
    ticketPrice: getFormattedMoney(ticketDefinition.price),
    fees: [getTax(event, ticketDefinition, t), getFee(event, ticketDefinition, t)].filter(Boolean).join(', '),
    ticketName: ticketDefinition.name,
    ticketId: ticketDefinition.id,
    ticketDescription: ticketDefinition.description,
    color,
    capacity,
    categoryId,
  }
}

export const getTicketDefinitionByExternalId = (tickets: wix.events.ticketing.TicketDefinition[], externalId: string) =>
  tickets.find(ticketDef => ticketDef.id === externalId.replace(WIX_EVENTS_TICKET_DEFINITION_FQDN, ''))

export const getCategoryById = (plan: SeatingPlan, categoryId: number) =>
  plan.categories.find(category => category.id === categoryId)
